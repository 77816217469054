import { yupResolver } from '@hookform/resolvers/yup'
import cookie from 'js-cookie'
import { Trans, useTranslation } from 'next-i18next'
import { ReactElement, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { MfaSetting } from '@fe/common/api/models/MfaSetting'
import { Button } from '@fe/common/components/Button'
import { Input } from '@fe/common/components/inputs/Input'
import { LoginForm } from '@fe/common/components/loggedOutStyles'
import { ErrorText } from '@fe/common/components/typography/ErrorText'
import { GDPR_HAS_AGREED_COOKIE_KEY } from '@fe/common/constants/cookies'
import { COLOR, FONT_WEIGHT } from '@fe/common/constants/main'
import { formatResponseError } from '@fe/common/utils/axiosUtils'
import { rercordEvent } from '@fe/common/utils/gaUtils'
import yup from '@fe/common/yup/yupConfig'
import { MfaApi } from 'src/api/MfaApi'
import { RegistrationApi } from 'src/api/RegistrationApi'
import { TokenApi } from 'src/api/TokenApi'
import { LOGIN_MFA_STEP } from 'src/constants/gaEvents'
import { configSelector } from 'src/state/selectors/globalDataSelectors'

export const StyledErrorText = styled(ErrorText)`
  a {
    font-weight: ${FONT_WEIGHT.SEMIBOLD};

    :hover {
      opacity: 0.7;
    }
  }
`

export const SuccessText = styled(ErrorText)`
  color: ${COLOR.GREEN};
`

const loginSchema = yup.object().shape({
  email: yup.string().email().trim().required(),
  password: yup.string().required(),
})

export interface LoginData {
  email: string
  password: string
}

interface CredentialsStepProps {
  formData: LoginData
  handleContinue: (values: LoginData, mfa_settings: Array<MfaSetting>) => void
}

export const CredentialsStep: React.FC<CredentialsStepProps> = ({
  formData,
  handleContinue,
}) => {
  const { t } = useTranslation('no-auth')
  const { company_email } = useSelector(configSelector)
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(true)
  const [isMfaReset, setIsMfaReset] = useState(false)
  const [isEmailResent, setIsEmailResent] = useState(false)
  const [isResending, setIsResending] = useState(false)
  const [error, setError] = useState<ReactElement | string>('')

  const { watch, register, handleSubmit, errors, formState, getValues } =
    useForm<LoginData>({
      mode: 'onBlur',
      resolver: yupResolver(loginSchema),
      defaultValues: formData,
    })

  const email = watch('email')

  useEffect(() => {
    if (!isEmailConfirmed || isMfaReset || isEmailResent) {
      setIsEmailResent(false)
      setIsEmailConfirmed(true)
      setIsMfaReset(false)
      setError(null)
    }
  }, [email])

  const onSubmit = async (values: LoginData) => {
    setIsEmailConfirmed(true)
    setIsEmailResent(false)
    setIsMfaReset(false)

    if (!cookie.get(GDPR_HAS_AGREED_COOKIE_KEY)) {
      setError(t('cookie-warning'))

      return
    }

    try {
      const { data } = await TokenApi.getLoginStatus(values)

      if (!data.is_email_confirmed) {
        setError(t('email-not-confirmed'))
        setIsEmailConfirmed(false)

        return
      }

      if (data.is_user_blocked) {
        setError(
          <Trans t={t} i18nKey="user-blocked" values={{ email: company_email }}>
            For logging in please contact support:
            <a href={`mailto:${company_email}`}>{company_email}</a>.
          </Trans>
        )

        return
      }

      if (data.is_mfa_reset) {
        setError(t('mfa-been-reset'))
        setIsMfaReset(true)

        return
      }

      rercordEvent('screen_view', {
        screen_name: LOGIN_MFA_STEP,
      })

      handleContinue(values, data.mfa_settings)
    } catch (error) {
      console.error(error)
      setError(formatResponseError(error))
    }
  }

  const resendEmail = async () => {
    const formValues = getValues()

    try {
      setIsResending(true)

      await RegistrationApi.resendEmail(formValues)

      setIsEmailResent(true)
      setError(null)
    } catch (error) {
      console.error(error)
      setError(formatResponseError(error))
    } finally {
      setIsResending(false)
      setIsEmailConfirmed(true)
    }
  }

  const resendMfaEmail = async () => {
    const formValues = getValues()

    try {
      setIsResending(true)

      await MfaApi.resendMfaRecovery(formValues)

      setIsEmailResent(true)
      setIsMfaReset(false)
      setError(null)
    } catch (error) {
      console.error(error)
      setError(formatResponseError(error))
    } finally {
      setIsResending(false)
    }
  }

  return (
    <>
      <LoginForm onSubmit={handleSubmit(onSubmit)}>
        <Input
          isFullWidth
          isFocused
          name="email"
          label={t('common:email')}
          type="email"
          errors={errors}
          formState={formState}
          ref={register}
        />

        <Input
          isFullWidth
          name="password"
          label={t('common:password')}
          type="password"
          errors={errors}
          formState={formState}
          ref={register}
        />

        {isEmailConfirmed && !isMfaReset && (
          <Button
            isFullWidth
            isSubmit
            isLoading={formState.isSubmitting}
            testId="continue_button"
          >
            {t('common:continue')}
          </Button>
        )}

        {!isEmailConfirmed && (
          <Button
            color={COLOR.ORANGE}
            isFullWidth
            isLoading={isResending}
            onClick={resendEmail}
          >
            {t('resend-email')}
          </Button>
        )}

        {isMfaReset && (
          <Button
            color={COLOR.ORANGE}
            isFullWidth
            isLoading={isResending}
            onClick={resendMfaEmail}
          >
            {t('resend-mfa-email')}
          </Button>
        )}
      </LoginForm>

      {error && (
        <StyledErrorText isAbsolute data-test-id="login_error">
          {error}
        </StyledErrorText>
      )}

      {isEmailResent && (
        <SuccessText isAbsolute data-test-id="email_resent">
          {t('email-resent')}
        </SuccessText>
      )}
    </>
  )
}
