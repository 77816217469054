import { useTranslation } from 'next-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { MfaSetting } from '@fe/common/api/models/MfaSetting'
import { MfaTypeEnum } from '@fe/common/api/models/enums/MfaTypeEnum'
import { BackButton } from '@fe/common/components/loggedOutStyles'
import { FONT_SIZE, SCREEN } from '@fe/common/constants/main'
import { getMfaTypeOptions } from '@fe/common/constants/options'
import { useCancelToken } from '@fe/common/hooks/useCancelToken'
// import { useRecaptcha } from '@fe/common/hooks/useRecaptcha'
import { LoginData } from './CredentialsStep'
import { TokenApi } from 'src/api/TokenApi'
import { MfaInput, MfaData } from 'src/components/MfaInput'
import { authenticate } from 'src/state/actions/sessionActions'
import { loginStateSelector } from 'src/state/selectors/sessionSelectors'
import { getDefaultMfa } from 'src/utils/stateUtils'

const InfoText = styled.p`
  margin-bottom: 4rem;
  font-size: ${FONT_SIZE.SMALL};
`

const StyledMfaInput = styled(MfaInput)`
  margin: 0 -1rem;

  ${SCREEN.ABOVE_MOBILE} {
    margin: 0;
  }
`

interface MfaStepProps {
  formData: LoginData
  mfaSettings: Array<MfaSetting>
  decrementStep: () => void
}

export const MfaStep: React.FC<MfaStepProps> = ({
  formData,
  mfaSettings,
  decrementStep,
}) => {
  const { t } = useTranslation('no-auth')
  const { loginError } = useSelector(loginStateSelector)

  // const { ReCaptcha, checkCaptcha } = useRecaptcha('login')
  const cancelToken = useCancelToken()
  const dispatch = useDispatch()

  const handleMfaPrepare = async (mfa_type: MfaTypeEnum) => {
    const { email } = formData

    const { data: preparationData } = await TokenApi.prepareTokenMfa(
      {
        mfa_type,
        email,
      },
      { cancelToken }
    )

    return preparationData
  }

  const onCodeEnter = async ({
    mfa_code,
    mfa_type,
    mfa_action_code,
  }: MfaData) => {
    const { email, password } = formData
    const uuid = uuidv4()
    const client_id = email + '-' + uuid

    // await checkCaptcha()

    await dispatch(
      authenticate({
        email,
        password,
        mfa_code,
        mfa_type,
        mfa_action_code,
        client_id,
        cancelToken,
      })
    )
  }

  const defaultMfa = getDefaultMfa(mfaSettings)
  const availableTypesOptions = getMfaTypeOptions(mfaSettings, t)

  return (
    <>
      <InfoText>{t('mfa-info')}</InfoText>

      <StyledMfaInput
        onCodeEnter={onCodeEnter}
        onMfaPrepare={handleMfaPrepare}
        externalTypes={availableTypesOptions}
        prefilledType={defaultMfa}
        externalError={loginError}
      />

      <BackButton onClick={() => decrementStep()} data-test-id="back_button">
        {t('common:back')}
      </BackButton>

      {/* <ReCaptcha /> */}
    </>
  )
}
