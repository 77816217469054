import { GetServerSideProps, NextPage } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Link from 'next/link'
import { useState, useEffect, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { MfaSetting } from '@fe/common/api/models/MfaSetting'
import { LoggedOutContainer } from '@fe/common/components/LoggedOutContainer'
import {
  BottomLink,
  BottomOptions,
} from '@fe/common/components/loggedOutStyles'
import { ErrorText } from '@fe/common/components/typography/ErrorText'
import { FONT_SIZE } from '@fe/common/constants/main'
import { SIGNUP } from '@fe/common/src/constants/routes'
import { getUserLocale } from '@fe/common/src/utils/nextUtils'
import { LoggedOutLayout } from 'src/components/layouts/LoggedOutLayout'
import {
  CredentialsStep,
  LoginData,
} from 'src/components/login/CredentialsStep'
import { MfaStep } from 'src/components/login/MfaStep'
import { FORGOT_PASSWORD } from 'src/constants/routes'
import { clearError } from 'src/state/reducers/sessionReducer'
import { loginStateSelector } from 'src/state/selectors/sessionSelectors'
import { withUnauthOnly } from 'src/utils/authUtils'

const LoginError = styled(ErrorText)`
  position: absolute;
  left: 0;
  display: inline-block;
  padding-top: ${FONT_SIZE.EXTRA_SMALL};
`

enum LoginSteps {
  Credentials,
  Mfa,
}

const Login: NextPage = () => {
  const { t } = useTranslation('no-auth')
  const { isLoginMfaWrong, loginError } = useSelector(loginStateSelector)
  const [step, setStep] = useState(LoginSteps.Credentials)
  const [formData, setFormData] = useState<LoginData>()
  const [mfaSettings, setMfaSettings] = useState<Array<MfaSetting>>()

  const dispatch = useDispatch()

  useLayoutEffect(() => {
    if (loginError) {
      dispatch(clearError())
    }
  }, [])

  useEffect(() => {
    if (loginError && !isLoginMfaWrong) {
      setStep(LoginSteps.Credentials)
    }
  }, [loginError])

  const handleContinue = (
    values: LoginData,
    mfa_settings: Array<MfaSetting>
  ) => {
    setFormData(values)
    setMfaSettings(mfa_settings)
    setStep(LoginSteps.Mfa)
    dispatch(clearError())
  }

  const decrementStep = () => setStep(LoginSteps.Credentials)

  const renderStep = () => {
    switch (step) {
      case LoginSteps.Credentials:
        return (
          <CredentialsStep
            formData={formData}
            handleContinue={handleContinue}
          />
        )

      case LoginSteps.Mfa:
        return (
          <MfaStep
            mfaSettings={mfaSettings}
            formData={formData}
            decrementStep={decrementStep}
          />
        )
    }
  }

  return (
    <LoggedOutLayout title={t('login')}>
      <LoggedOutContainer data-test-id="login" heading={t('login')}>
        {renderStep()}

        {loginError && !isLoginMfaWrong && (
          <LoginError data-test-id="error">{t(loginError)}</LoginError>
        )}

        <BottomOptions>
          <Link href={SIGNUP} passHref>
            <BottomLink data-test-id="create_account_link">
              {t('create-account')}
            </BottomLink>
          </Link>

          <Link href={FORGOT_PASSWORD} passHref>
            <BottomLink data-test-id="forgot_password_link">
              {t('forgot-password')}
            </BottomLink>
          </Link>
        </BottomOptions>
      </LoggedOutContainer>
    </LoggedOutLayout>
  )
}

export const getServerSideProps: GetServerSideProps = withUnauthOnly(
  async ({ req, locale, locales }) => {
    const userLocale = getUserLocale(req, locale, locales)

    return {
      props: {
        ...(await serverSideTranslations(userLocale, [
          'common',
          'no-auth',
          'errors',
        ])),
      },
    }
  }
)

export default Login
